
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
   
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule
} from 'devextreme-vue/data-grid';

import { DxToast } from 'devextreme-vue/toast';
 
 
import HabilidadService from '../services/habilidad-service';
import UsuarioHabilidadService from '../services/usuariohabilidad-service';




export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
       
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule
    },
    props: {
    supplierId: {
      type: Number,
      default: null,
    },
  },
    data() {
        
        return {
            
            procedimientos: null,
            dataSourceUsuarios: [
                {id:1,estatusanterior:'Solicitada',estatusnuevo:'En Revision',fecharegistro:'10/10/2022 17:15',usuario:'Pedro Martinez Perez',servicio:'Solicitud'},
                 {id:2,estatusanterior:'En Revision',estatusnuevo:'En Cotizacion',fecharegistro:'11/10/2022 10:15',usuario:'Pedro Martinez Perez',servicio:'OT0001-Asesoria Global Ambiental Tipo Poliza'},
                  {id:3,estatusanterior:'En Cotizacion',estatusnuevo:'Aprobada',fecharegistro:'16/10/2022 11:35',usuario:'Juan Alvarado',servicio:'OT0002-Asesoria en Procedimiento Administrativo'},
                 
            ],
            dataSourceRoles: null,
             dataSourceHabilidades:  [
                {id:1,descripcion:'Solicitud Cliente'},
                {id:2,descripcion:'Minuta'},
                 {id:3,descripcion:'Evidencia Aprobacion'},
                  {id:4,descripcion:'Evidencia Rechazo'},
                   {id:5,descripcion:'Documento Evidencia Avance'}
            ],
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Agregar Documentos',
                showTitle: true,
                width: 400,
                height: 250,
            },
             
           
            
        };
    },
    created() {
        
         this.usuariohabilidadService = new UsuarioHabilidadService();
        this.habilidadService = new HabilidadService();

    },
    mounted() {
        
        //this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
             
        
            //this.usuarioService.getUsuario().then(data => this.dataSourceUsuarios = data);
            let idusuario = this.supplierId;
            let myJSON = JSON.stringify({
                pusuarioid: idusuario
            });
            this.usuariohabilidadService.getUsuarioHabilidad(myJSON).then(data => this.dataSourceUsuarios = data);
            this.habilidadService.getHabilidad().then(data => this.dataSourceHabilidades = data);
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
       
        deleteRegister(e) {
            console.log(e.data);
            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.usuariohabilidadService.deleteUsuarioHabilidad(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {


            let idusuario =this.supplierId;
            let myJSON = JSON.stringify({
                pusuarioid: idusuario,
                phabilidadid: e.data.habilidadid,

            });


            this.usuariohabilidadService.createUsuarioHabilidad(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
